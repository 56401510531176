import React, { Component } from 'react'
import { Helmet } from 'react-helmet'



import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";

import defaultProfile from "../img/default-profile.png";
import logo from '../img/logo.png'

import { Link } from 'gatsby';
let apiURL = "https://api.globelynx.com/api/"

export default class Blogs extends Component {

  constructor(props) {
    super(props)

    this.state = {
      allBlogs: []
    }

    this.urlExists = this.urlExists.bind(this)
  }

  urlExists(url, callback) {
    fetch(url, { method: 'head' })
      .then(function (status) {
        callback(status.ok)
      });
  }


  componentDidMount() {
    var self = this

    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };


    fetch(apiURL + "blogs", requestOptions)
      .then(response => response.json())
      .then(result => {
        var path = []
        result.map((val, key) => {

          var str = decodeURI(val.blogsName);
          str = str.replace(/[^a-z\d\s]+/gi, " ")
          str = str.replace(/\s+/g, " ").trim();
          str = str.replace(/\s+/g, "-").toLowerCase();

          if (typeof document !== 'undefined' || typeof window !== 'undefined') {
            self.urlExists(window.location.origin + '/' + str, function (exists) {
              if (exists) {
                path.push({
                  ...val,
                  pathname: str
                })
              } else {
              }

              self.setState({
                allBlogs: path,
              })
            });
          }
        })

      })
      .catch(error => console.log('error', error));
  }

  render() {



    return (
      <Layout>
        <Helmet>
          <title>NEWS & Views From Globelynx HQ</title>
          <meta
            name="og:title"
            content={`Globelynx Blog | News & Views From Globelynx HQ`}
          />

          <meta
            name="og:description"
            content={`Keep up-to-date with news and views from Globelynx HQ. Hear from our team, our experts & guest contributors, or delve into our top tips for expert interviews!
            `}
          />

        </Helmet>

        <div className="container-fluid">
          <div className="row">
            <div className="has-text-centered has-padding">
              <h1 className=" has-text-centered">News & Views From Globelynx HQ</h1>
              <p>Keep up-to-date with news and views from Globelynx HQ. Hear from our team, our experts & guest contributors, or delve into our top tips for expert interviews!</p>
            </div>
          </div>
        </div>


        <section className="section">
          <div className="container-fluid">
            <div className="row">
              {
                this.state.allBlogs.length !== 0 ?
                  this.state.allBlogs.sort((a, b) => {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  }).map((val, key) => {
                    return (
                      <Link to={`/${val.pathname}`}>
                        <div
                          className="four columns"
                          style={{ padding: '25px', background: '#f7f5f6' }}
                        >
                          <article>
                            <header>
                              <div
                                style={{ position: 'relative', width: '100%', height: 500 * 0.65, overflow: 'hidden', background: '#fff' }}
                              >

                                <img src={val.headerImage} onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.style.objectFit = 'scale-down';
                                  e.target.style.background = '#4e5863';
                                  e.target.src = logo;
                                }} style={{ position: 'absolute', width: '100%', height: 'auto', left: 0, top: '50%', transform: 'translateY(-50%)' }} />
                              </div>


                              {/* <div className="featured-thumbnail">
                                <img className="featured-profile-image" src={val.headerImage} style={{ width: '100%', maxHeight: 250 }} onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.style.objectFit = 'scale-down';
                                  e.target.style.background = '#4e5863';
                                  e.target.src = logo;
                                }} />
                              </div> */}
                              <h2 style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }}>
                                <Link
                                  to={`/${val.pathname}`}
                                  style={{ textDecoration: 'none', color: '#4e5863' }}
                                >
                                  {decodeURI(val.blogsName)}
                                </Link>
                                <span> &bull; </span>
                              </h2>
                            </header>
                            <p style={{
                              display: "block",
                              fontSize: 14,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}>
                              {val.blogsContent.replace(/&nbsp;/g, ' ').replace(/<\/?[^>]+(>|$)/g, "")}
                              <br />
                              <br />
                              <Link
                                style={{ textDecoration: 'none', color: '#4e5863' }}
                                to={`/${val.pathname}`}
                              >
                                Keep Reading →
                  </Link >
                            </p>
                          </article>
                        </div>
                      </Link>
                    )
                  }) : ""
              }
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
